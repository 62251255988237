<template>
  <div class="container-fluid pb-5">
    <div class="row">
      <div class="col pr-0 pl-0">
        <!-- <img class="img-fluid" alt="Angielska Taksówka" src="../assets/wedding-cab-0.jpg"> -->
        <img class="ws-lazy-load loaded img-fluid" loading="lazy" sizes="(min-width: 1920px) 1920px, 100vw" 
        srcset="../assets/wedding-cab-0-500.jpg 500w, ../assets/wedding-cab-0-800.jpg 800w, ../assets/wedding-cab-0-1024.jpg 1024w ../assets/wedding-cab-0-1920.jpg 1920w" 
        src="../assets/wedding-cab-0-1920.jpg" data-ll-status="loaded">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <welcome-text />
      </div>
    </div>
  
  </div>
</template>

<script>
import WelcomeText from '@/components/WelcomeText.vue'

export default {
  name: 'Home',
  components: {
    WelcomeText
  }
}
</script>

