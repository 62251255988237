<template>
  <div id="app">
    <nav id="nav" class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <router-link class="navbar-brand styled-text" tag="a" to="/">
        <img src="@/assets/london-cab-gray.png" width="60" height="60">Taksówką na Ślub
      </router-link>
        
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" data-toggle="collapse" data-target="#navbarCollapse">
            <router-link class="nav-link" tag="a" to="/">Home</router-link>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target="#navbarCollapse">
            <router-link class="nav-link" tag="a" to="/what-we-offer">Oferta</router-link>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target="#navbarCollapse">
            <router-link class="nav-link" tag="a" to="/contact-us">Kontakt</router-link>
          </li>
        </ul>

        <ul class="navbar-nav form-inline mt-2 mt-md-0">
          <li class="nav-item" data-toggle="collapse" data-target="#navbarCollapse">
            <router-link class="nav-link btn btn-outline-warning btn-sm p-1" tag="button" to="/contact-us">Zapytaj o wolny termin</router-link>
          </li>
          </ul>
      </div>
    </nav>

    <router-view class="mt-5 pb-5 pt-4" />

    <!-- <div class="container">
      <div class="row">
        <div class="col">
          <h6 class="text-muted text-left">© 2020 SquareWave</h6>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
// import img from '@/assets/favicon.png';

export default {
  name: "App",
  data() {
    return {
      brandImgLocation: 'assets/favicon.png'
    }
  }
}
</script>

<style>
/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Raleway:wght@100;200&display=swap');

#app {
  display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: rgb(236, 236, 236);
}

#nav {
  padding: 10px;
}

.styled-title {
  font-family: 'Great Vibes', cursive;
}

.styled-text {
  font-family: 'Raleway', sans-serif;
}
</style>
