<template>
  <div>
    <h1 class="m-4 styled-title">:: Witamy</h1>
    <h5 class="m-2 text-muted styled-text">Zapraszamy do zapoznania się z naszą <router-link class="hiper-link" tag="a" to="/what-we-offer">ofertą</router-link>.</h5>
    <h3 class="m-3 styled-text">Londyńska taksówka do wynajęcia na ślub, wesele, reklamę, wieczory panieńskie, kawalerskie, oraz inne okoliczności.</h3>
    <h6 class="m-4 styled-text">Działamy na terenie powiatu nowotarskiego i tatrzańskiego (województwo małopolskie). Aczkolwiek, jesteśmy w stanie dostosować się do potrzeb klienta.</h6>
    <router-link class="btn btn-secondary mt-3" tag="a" to="/contact-us">Zapytaj o wolny termin</router-link>
  </div>
</template>

<script>
export default {
  name: 'WelcomeText',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.hiper-link {
  color: darkslategrey;
  font-weight: bold;
}
</style>
